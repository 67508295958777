module.exports = doc => {
  // console.log(doc.type, doc.uid)
  const {lang, type, uid} = doc
  switch (type) {
    case "page":
      return lang === 'fr-fr' ? `/${uid}` : `/${getLangByCode(lang)}/${uid}`
    case "presentation":
      return lang === 'fr-fr' ? `/${uid}` : `/${getLangByCode(lang)}/${uid}`

    default:
      return "/"
  }
}

const getLangByCode = (lang) => {
  switch(lang){
    case 'fr-fr':
      return 'fr'
    case 'en-gb':
      return 'en'
  }
};